import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { associationsList } from '../../../hooks/auth/actions';
import { IAssociationId } from '../../../models/associationmodels';
import { error } from 'console';

interface SearchAssociationsProps {
  setSelectedAssociation: React.Dispatch<React.SetStateAction<string | null>>
  associationError: boolean;
  setAssociationError: React.Dispatch<React.SetStateAction<boolean>>
}

const SearchAssociations: React.FC<SearchAssociationsProps> = ({ setSelectedAssociation, associationError, setAssociationError }) => {
    const { t } = useTranslation();
    const [accociations, setAssociations] = React.useState<IAssociationId[]>([])

    React.useEffect(()=>{
      associationsList().then(result => {
        setAssociations(result)
      }).catch(e=>{
        // console.log("errore lista associazioni", e.response )
        setAssociations([])
      })
    },[])

    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={accociations}
        getOptionLabel={(option) => option.descriptionName}
        clearOnEscape
        onChange = {
          (event, newValue) => {
            setSelectedAssociation(newValue ? newValue.associationId as string : null);
            if(newValue) setAssociationError(false)
          }
        }
        renderInput={(params) => <TextField 
            margin="normal" 
            {...params} 
            required
            id='association'
            name="association"
            autoComplete="association"
            label={!associationError ? t("Associazione di appartenenza") : t("Errore")} 
            error={associationError}
            helperText={
              associationError ? t("Scelta Obbligatoria") : ""
            }
            />
          }
        />
    );
  }

  export default SearchAssociations;