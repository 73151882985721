import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { associationsList, nationsList } from '../../../hooks/auth/actions';
import { IAssociationId } from '../../../models/associationmodels';
import { error } from 'console';

interface SearchNationsProps {
  setNazione: React.Dispatch<React.SetStateAction<string | null>>
  nazioneError: boolean;
  setNazioneError: React.Dispatch<React.SetStateAction<boolean>>
}

const SearchNations: React.FC<SearchNationsProps> = ({ setNazione, nazioneError, setNazioneError }) => {
    const { t } = useTranslation();
    const [nations, setNations] = React.useState<string[]>([])

    React.useEffect(()=>{
      nationsList().then(result => {
        setNations(result)
      }).catch(e=>{
        setNations([]);
        // console.log("errore lista nazioni", e.response )
      })
    },[])

    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={nations}
        clearOnEscape
        onChange = {
          (event, newValue) => {
            setNazione(newValue ? newValue as string : null);
            if(newValue) setNazioneError(false)
          }
        }
        renderInput={(params) => <TextField 
            margin="normal" 
            {...params} 
            required
            id='nazione'
            name="nazione"
            autoComplete="nazione"
            label={!nazioneError ? t("Nazione") : t("Errore")} 
            error={nazioneError}
            helperText={
              nazioneError ? t("Scelta Obbligatoria") : ""
            }
            />
          }
        />
    );
  }

  export default SearchNations;