import httpClient from "../../utilities/http-client";
import { IConfirmEmailRequest, ILoggedOutModel, IRegisterModel, IAcceptRejectRequest, IRequestResetPassword, IRequestUpdatePassword, IRegisterCompnyModel } from "../../models/authmodels";

export const login = async (email: string, password: string, returnUrl: string, rememberMe:boolean) => {
	try {
		const { data } = await httpClient.post("/authenticate/login", {
			email,
			password,
			returnUrl,
			rememberMe
		});
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const logout = async (logoutId: string) => {
	try {
		const { data } = await httpClient.post<ILoggedOutModel>("/authenticate/logout", { logoutId });
		return data;
	} catch (e: any) {
		throw e;
	}
};

export const associationsList = async () => {
	try {
		const { data } = await httpClient.get("/associations/list");
		return data;
	} catch (e: any) {
		throw e?.message || e || "exception during request associations list";
	}
}

export const nationsList = async () => {
	try {
		const { data } = await httpClient.get("/nations/list");
		return data;
	} catch (e: any) {
		throw e?.message || e || "exception during request associations list";
	}
}

export const register = async (model:IRegisterModel) => {
	try {
		const { data } = await httpClient.post("/authenticate/register", model);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const registerCompany = async (model:IRegisterCompnyModel) => {
	try {
		const { data } = await httpClient.post("/authenticate/register-company", model);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const confirmEmail = async (model:IConfirmEmailRequest) => {
	try {
		const { data } = await httpClient.post("/authenticate/confirm", model);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const acceptRejectUser = async (model:IAcceptRejectRequest) => {
	try {
		const { data } = await httpClient.post("/associations/confirmuser", model);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const requestResetPasswordLink = async (model:IRequestResetPassword) => {
	try {
		const { data } = await httpClient.post("/authenticate/password/reset", model);
		return data;
	} catch (e: any) {
		throw  e;
	}
};

export const updatePassword = async (model:IRequestUpdatePassword) => {
	try {
		const { data } = await httpClient.post("/authenticate/password/update", model);
		return data;
	} catch (e: any) {
		throw  e;
	}
};