import * as React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { IRequestResetPassword } from '../../../../models/authmodels';
import { useSearchParams } from 'react-router-dom';
import { requestResetPasswordLink } from '../../../../hooks/auth/actions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DropDownLanguages from '../../../DropDownLanguages';
import settings from '../../../../utilities/settings';

export default function RequestResetLink() {
    dayjs.extend(customParseFormat);
    const { t } = useTranslation();
    const [searchParams] = useSearchParams(); // parametri url

    const ReturnUrl = searchParams.get("ReturnUrl") !== null ? searchParams.get("ReturnUrl") as string : "/"

    const [mail, setMail] = useState('');
    const [mailError, setMailError] = useState(false);

    const [genericMessage, setGenericMessage] = useState(false)
    const [genericMessageIsError, setGenericMessageIsError] = useState(false)
    const [genericMessageText, setGenericMessageText] = useState('')

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(()=>{
         // reset messaggio errore generico
         setGenericMessage(false)
         setGenericMessageIsError(false)
         setGenericMessageText('')
    },[])

    function removeFirstSpaceChar(str:string) {
        if (str.charAt(0) === ' ') {
          return str.slice(1);
        } else {
          return str;
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key:string) => {
        let value = removeFirstSpaceChar(e.target.value)
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = regex.test(value)
        setMail(value)
        if(isValid){
            setMailError(false)
        } else {
            setMailError(true)
        }
    }
    
    const isFormCorrect = ( 
        mail === ''
    ) ? false : true

    function checkAreNotEmpies(): boolean {
        let areCorrects = true;
        // reset messaggio errore generico
        setGenericMessage(false)
        setGenericMessageIsError(false)
        setGenericMessageText('')

        if(mail === '') {setMailError(true); areCorrects = false}
        
        if (!areCorrects) {
            setGenericMessage(true)
            setGenericMessageIsError(true)
            setGenericMessageText(t("Ci sono degli errori nel form"))
        }
        
        return areCorrects;
    }
    
    function inputClean(){
        setMail('')
    } 

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!mailError){
            const areCorrects = checkAreNotEmpies();
            if(!areCorrects) return;

            setIsSubmit(true)
            // se tutto va bene:
            const resetPwModel: IRequestResetPassword = {
                email: mail,
                returnUrl: ReturnUrl
            };

            await requestResetPasswordLink(resetPwModel).then(resp =>{
                inputClean()
                setGenericMessage(true)
                setGenericMessageIsError(false)
                const message = "Ti abbiamo inviato nella tua mail il link per recuperare la tua password."
                setGenericMessageText(t(message))
            }).catch(e => {
                setGenericMessage(true)
                setGenericMessageIsError(true)
                const message:any = e?.response?.data || e?.response || e || "Errore durante la richiesta del link per cambio password. Riprova più tardi."
                setGenericMessageText(t(message))
            })
        }else{
            setGenericMessage(true)
            setGenericMessageIsError(true)
            setGenericMessageText(t("Ci sono degli errori nel form"))
        }
    };
  
    return (
        <Container component="main" maxWidth="xs" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border:'1px solid rgb(128, 128, 128, 0.35)',
            margin:'30px 0px 30px 0px',
            borderRadius:'5px'
        }}
        >
            <Box
                sx={{
                    padding:'8px 0 0 0',
                    width:'100%',
                    height:'35px',
                    marginBottom:'30px',
                    display:'flex'
                }}
            >
                <Box sx={{
                    padding:'0px',
                    width:'100px',
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'center'
                }}>
                    <img src={"/"+settings.Details.Logo} alt="Plutima Provider" style={{
                        height:'35px',
                        marginTop:'8px'
                    }}/>
                </Box>
                <Box sx={{
                    padding:'0px',
                    flex:1,
                    display:'flex',
                    justifyContent:'end',
                    alignItems:'center'
                }}>
                    <DropDownLanguages />
                </Box>
            </Box>
            
            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: 'rgb(156, 39, 176)', color: 'white', alignItems:'center' }}>
                <ArrowBackIcon sx={{fontSize:50}}/>
            </Avatar>

            <Box
                sx={{
                    height: 10,
                }}
            />

            <Typography component="h1" variant="h5">
                {t('Reset Password')}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={!mailError ? t('Indirizzo Email'): t("Errore")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={mail}
                    onChange={(event) => handleChange(event, 'email')}
                    error={mailError}
                    helperText={
                        mailError ? t("Inserisci un indirizzo email valido") : ""
                    }
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!isFormCorrect || isSubmit}
                >
                    {t('Richiedi Reset Password')}
                </Button>

            </Box>

            { genericMessage && 
                <Box
                    sx={{
                        maxHeight: '200px',
                        overflow:'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    <Typography variant='h6'
                        sx={{color: genericMessageIsError ? "red" : "green"}}
                    >
                        { genericMessageText }
                    </Typography> 
                </Box>
            }

            <Box sx={{
                height: 40,
            }}/>
        </Container>
    )
}