import * as React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import lastDate18YearsAgo from '../../../hooks/lastDate18years';
import SearchAssociations from './searchAssociation';
import { IRegisterModel } from '../../../models/authmodels';
import { useSearchParams } from 'react-router-dom';
import { register } from '../../../hooks/auth/actions';
import SearchNations from './searchNation';
import { delay } from '../../../utilities/delay';
import settings from '../../../utilities/settings';
import DropDownLanguages from '../../DropDownLanguages';


export default function Register() {
    dayjs.extend(customParseFormat);
    const { t } = useTranslation();
    const [searchParams] = useSearchParams(); // parametri url

    const ReturnUrl = searchParams.get("ReturnUrl") !== null ? searchParams.get("ReturnUrl") as string : "/account/confirm-mail"

    const [selectedAssociation, setSelectedAssociation] = useState<string | null>(null);
    const [associationError, setAssociationError] = useState(false);
    
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(); //dayjs(lastDate18YearsAgo())
    const [bornError, setBornError] = useState(false);
    const [bornErrorMessage, setBornErrorMessage] = useState('');
    const [maxData, setMaxData] = useState<Dayjs | null>(dayjs(lastDate18YearsAgo()));
    
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    
    const [surname, setSurname] = useState('');
    const [surnameError, setSurnameError] = useState(false);

    const [codfisc, setCodFisc] = useState('');
    const [codfiscError, setCodFiscError] = useState(false);

    const [indirizzo, setIndirizzo] = useState('');
    const [indirizzoError, setIndirizzoError] = useState(false);

    const [citta, setCitta] = useState('');
    const [cittaError, setCittaError] = useState(false);

    const [cap, setCap] = useState('');
    const [capError, setCapError] = useState(false);

    const [nazione, setNazione] = useState<string | null>('');
    const [nazioneError, setNazioneError] = useState(false);
    
    const [provincia, setProvincia] = useState('');
    const [provinciaError, setProvinciaError] = useState(false);

    const [mail, setMail] = useState('');
    const [mailError, setMailError] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErroMessage] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const [passwordConfirmErrorMessage, setPasswordConfirmErroMessage] = useState('');

    const [genericMessage, setGenericMessage] = useState(false)
    const [genericMessageIsError, setGenericMessageIsError] = useState(false)
    const [genericMessageText, setGenericMessageText] = useState('')

    const [isSubmit, setIsSubmit] = useState(false);

    const minPasswordLength = 8;

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    
    useEffect(()=>{
        //  // reset messaggio errore generico
        //  setGenericMessage(false)
        //  setGenericMessageIsError(false)
        //  setGenericMessageText('')
    },[selectedDate, selectedAssociation, name, surname, indirizzo, citta, cap, nazione, provincia, password, confirmPassword, mail])


    function removeFirstSpaceChar(str:string) {
        if (str.charAt(0) === ' ') {
          return str.slice(1);
        } else {
          return str;
        }
      }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key:string) => {
        switch(key){
            case 'name': {
                const value = removeFirstSpaceChar(e.target.value)
                const regex = /^(?=.*[A-Za-z])[A-Za-z ]+$/
                const isValid = regex.test(value)
                setName(value);
                if (isValid) {
                    setNameError(false);
                } else {
                    if( value === '' ){
                        setNameError(false);
                    }else{
                        setNameError(true);
                    }   
                }
            } break
            case 'surname': {
                const value = removeFirstSpaceChar(e.target.value)
                const regex = /^(?=.*[A-Za-z])[A-Za-z ]+$/
                const isValid = regex.test(value)
                setSurname(value);
                if (isValid) {
                    setSurnameError(false);
                } else {
                    if( value === '' ){
                        setSurnameError(false);
                    }else{
                        setSurnameError(true);
                    }   
                }
            } break
            case 'password':{
                const value = removeFirstSpaceChar(e.target.value)

                setPassword(e.target.value)
                setPasswordError(false);
                setPasswordErroMessage("");
            } break
            case 'confirmPassword':{
                setConfirmPassword(e.target.value)
                setPasswordConfirmError(false)
                setPasswordConfirmErroMessage('')
            }break
            case 'indirizzo': {
                const value = removeFirstSpaceChar(e.target.value)
                const regex = /^[A-Za-z0-9 ,\/-]*$/
                const isValid = regex.test(value)
                setIndirizzo(value);
                if (isValid) {
                    setIndirizzoError(false);
                } else {
                    if( value === '' ){
                        setIndirizzoError(false);
                    }else{
                        setIndirizzoError(true);
                    }   
                }
            } break
            case 'citta': {
                const value = removeFirstSpaceChar(e.target.value)
                const regex = /^(?=.*[A-Za-z])[A-Za-z ]+$/
                const isValid = regex.test(value)
                setCitta(value);
                if (isValid) {
                    setCittaError(false);
                } else {
                    if( value === '' ){
                        setCittaError(false);
                    }else{
                        setCittaError(true);
                    }   
                }
            } break
            case 'cap': {
                const value = removeFirstSpaceChar(e.target.value)
                const regex = /^[0-9]+$/
                const isValid = regex.test(value)
                setCap(value);
                if (isValid) {
                    setCapError(false);
                } else {
                    if( value === '' ){
                        setCapError(false);
                    }else{
                        setCapError(true);
                    }   
                }
            } break
            case 'nazione': {
                const value = removeFirstSpaceChar(e.target.value)
                const regex = /^(?=.*[A-Za-z])[A-Za-z ]+$/
                const isValid = regex.test(value)
                setNazione(value);
                if (isValid) {
                    setNazioneError(false);
                } else {
                    if( value === '' ){
                        setNazioneError(false);
                    }else{
                        setNazioneError(true);
                    }   
                }
            } break
            case 'provincia': {
                let value = removeFirstSpaceChar(e.target.value)
                const regex = /^[A-Za-z]+$/
                const isValid = regex.test(value)
                if (value)
                    value = value.toUpperCase()
                setProvincia(value);
                if (isValid) {
                    setProvinciaError(false);
                } else {
                    if( value === '' ){
                        setProvinciaError(false);
                    }else{
                        setProvinciaError(true);
                    }   
                }
            } break
            case 'email': {
                let value = removeFirstSpaceChar(e.target.value)
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const isValid = regex.test(value)
                setMail(value)
                if(isValid){
                    setMailError(false)
                } else {
                    setMailError(true)
                }
            }break
            case 'codfisc': {
                const value = removeFirstSpaceChar(e.target.value)
                const regex = /^[A-Za-z0-9]*$/
                const isValid = regex.test(value)
                setCodFisc(value);
                if (isValid) {
                    setCodFiscError(false);
                } else {
                    if( value === '' ){
                        setCodFiscError(false);
                    }else{
                        setCodFiscError(true);
                    }   
                }
            } break
        }
    };

    const handlePasswordCheckOnBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\-_+=])(?=.*[0-9])[A-Za-z0-9!@#$%^&*()\\-_+=]+$/
        const isValid = pwRegex.test(e.target.value)
        if (e.target.value.length < minPasswordLength){
            setPasswordError(true)
            setPasswordErroMessage(`La password deve contenere almeno ${minPasswordLength} caratteri`)
            return
        }
        if(!isValid)
        {
                setPasswordError(true);
                setPasswordErroMessage(`Password non valida! Deve contenere almeno 1 maiuscola, 1 minuscola, 1 carattere speciale e 1 numero e non avere spazi`)
        }else{
            setPasswordError(false)
            setPasswordErroMessage("")
        }
    }

    const handleConfirmPasswordCheckOnBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (password !== confirmPassword){ 
            setPasswordConfirmError(true);
            setPasswordConfirmErroMessage(`Le password non corrispondono`)
        }else{
            setPasswordConfirmError(false);
            setPasswordConfirmErroMessage(``);
        }
    }

    const handleChangeDate = (date: Dayjs | null) => {
        setBornErrorMessage('')
        if(date !== null && date.isValid()){ // se la data è valida mi setti false, ma se per caso dovesse essere meno di 18 anni fai true all'errore
            setSelectedDate(date)
            setBornError(false)

            if(maxData !== null && date > maxData){
                setBornErrorMessage(t("Devi avere più di 18 anni per iscriverti"))
                setBornError(true)
            } 
        } 
        if (date !== null && !date.isValid()){
            setBornErrorMessage(t("Data non valida"))
            setBornError(true)
        }
    };

    const isFormCorrect = ( 
        name === '' || 
        surname === '' ||
        indirizzo === '' ||
        citta === '' ||
        cap === '' ||
        nazione === '' ||
        provincia === '' ||
        password === '' ||
        selectedAssociation === null || 
        selectedAssociation === "" || 
        password !== confirmPassword ||
        mail === '' ||
        codfisc === ''
    ) ? false : true

    function checkAreNotEmpies(): boolean {
        let areCorrects = true;
        // reset messaggio errore generico
        setGenericMessage(false)
        setGenericMessageIsError(false)
        setGenericMessageText('')

        //check con set degli errori singoli
        if(name === '') {setNameError(true); areCorrects = false}
        if(surname === '') {setSurnameError(true); areCorrects = false}
        if(indirizzo === '') {setIndirizzoError(true); areCorrects = false}
        if(citta === '') {setCittaError(true); areCorrects = false}
        if(cap === '') {setCapError(true); areCorrects = false}
        if(nazione === null || nazione === "") {setNazioneError(true); areCorrects = false}
        if(provincia === '') {setProvinciaError(true); areCorrects = false}
        if(password === '') {setPasswordError(true); areCorrects = false}
        if(password !== confirmPassword) {setPasswordConfirmError(true); setPasswordConfirmErroMessage("Le password non corrispondono"); areCorrects = false}
        if(selectedAssociation === null || selectedAssociation === "") {setAssociationError(true); areCorrects = false}
        if(mail === '') {setMailError(true); areCorrects = false}
        if(codfisc === '') {setCodFiscError(true); areCorrects = false}
        
        if (!areCorrects) {
            setGenericMessage(true)
            setGenericMessageIsError(true)
            setGenericMessageText(t("Ci sono degli errori nel form"))
        }
        
        return areCorrects;
    }
    
    function inputClean(){
        setName("")
        setSurname('')
        setCap('')
        setSelectedAssociation(null)
        setSelectedDate(dayjs(lastDate18YearsAgo()))
        setIndirizzo('')
        setCitta('')
        setNazione(null)
        setProvincia('')
        setMail('')
        setPassword('')
        setConfirmPassword('')
        setCodFisc('')
    } 

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // verifico che non abbia errori in memoria
        if( !bornError && 
            !nameError && 
            !surnameError &&
            !indirizzoError &&
            !cittaError &&
            !capError &&
            !nazioneError &&
            !provinciaError &&
            !passwordError &&
            !passwordConfirmError &&
            !associationError &&
            !codfiscError && 
            !mailError // verificare
        ){
            const areCorrects = checkAreNotEmpies();
            if(!areCorrects) return;

            setIsSubmit(true)
            // se tutto va bene:
            const regModel: IRegisterModel = {
                associationId: selectedAssociation ? selectedAssociation : '',
                email: mail,
                password: password,
                returnUrl: ReturnUrl,
                name: name,
                surname: surname,
                codFisc: codfisc,
                born: selectedDate ? selectedDate.toDate() : new Date(),
                indirizzo: indirizzo,
                citta: citta,
                cap: parseInt(cap),
                provincia: provincia,
                nazione:nazione ? nazione : '',
            };

            await register(regModel).then(response => {
                setIsSubmit(false)
                setGenericMessage(true)
                setGenericMessageIsError(false)
                const message = "Registrazione avvenuta con successo! Approva la mail che ti abbiamo inviato per concludere la procedura!"
                setGenericMessageText(t(message))
                inputClean()
                
                if(searchParams.get("ReturnUrl") !== null){
                    delay(5000).then(r=>{
                        const message = "Stai per essere reindirizzato alla pagina di provenienza..."
                        setGenericMessageText(t(message));
                    }).finally(()=>{ 
                        delay(5000).then(rr=>{
                            return window.location.href = ReturnUrl;
                        })
                    })
                    
                }else{
                   return window.location.href = ReturnUrl+"?userName="+response.userName;
                }
            }).catch(e=>{
                setIsSubmit(false)
                console.log("REGISTER ERROR", e)
                setGenericMessage(true)
                setGenericMessageIsError(true)
                const message:any = e?.response?.data || e?.response || e || "Errore durante la registrazione. Riprova più tardi."
                setGenericMessageText(t(message))
            })
        }else{
            setGenericMessage(true)
            setGenericMessageIsError(true)
            setGenericMessageText(t("Ci sono degli errori nel form"))
        }
    };
  
    return (
        <Container component="main" maxWidth="xs" 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border:'1px solid rgb(128, 128, 128, 0.35)',
                margin:'30px 0px 30px 0px',
                borderRadius:'5px'
            }}
        >
            <Box
                sx={{
                    padding:'8px 0 0 0',
                    width:'100%',
                    height:'35px',
                    marginBottom:'30px',
                    display:'flex'
                }}
            >
                <Box sx={{
                    padding:'0px',
                    width:'100px',
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'center'
                }}>
                    <img src={"/"+settings.Details.Logo} alt="Plutima Provider" style={{
                        height:'35px',
                        marginTop:'8px'
                    }}/>
                </Box>
                <Box sx={{
                    padding:'0px',
                    flex:1,
                    display:'flex',
                    justifyContent:'end',
                    alignItems:'center'
                }}>
                    <DropDownLanguages />
                </Box>
            </Box>

            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: 'rgb(156, 39, 176)', color: 'white', alignItems:'center' }}>
                <LockOutlinedIcon sx={{fontSize:50}}/>
            </Avatar>

            <Box
                sx={{
                    height: 10,
                }}
            />

            <Typography component="h1" variant="h5">
                {t('Registrati come Privato')}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label={!nameError ? t('Nome'): t("Errore")}
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={name}
                    onChange={(event) => handleChange(event, 'name')}
                    error={nameError}
                    helperText={
                        nameError ? t("Campo Obbligatorio - Richieste lettere e spazi") : ""
                    }
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="surname"
                    label={!surnameError ? t('Cognome') : t("Errore")}
                    name="surname"
                    autoComplete="surname"
                    autoFocus
                    value={surname}
                    onChange={(event) => handleChange(event, 'surname')}
                    error={surnameError}
                    helperText={
                        surnameError ? t("Campo Obbligatorio - Richieste lettere e spazi") : ""
                    }
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="codfisc"
                    label={!codfiscError ? t('Codice Fiscale'): t("Errore")}
                    name="codfisc"
                    autoComplete="codfisc"
                    autoFocus
                    value={codfisc}
                    onChange={(event) => handleChange(event, 'codfisc')}
                    error={codfiscError}
                    helperText={
                        codfiscError ? t("Campo Obbligatorio - Richiesti lettere e numeri") : ""
                    }
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack sx={{
                        marginTop:'16px',
                        marginBottom:'8px'
                    }}>
                        <DatePicker 
                            format="DD/MM/YYYY"
                            disableFuture
                            label={!bornError ? (t('Data di Nascita')) : t("Errore")}
                            views={['day', 'month', 'year']}
                            // maxDate={maxData}
                            value={selectedDate}
                            onChange={(date) => {
                                handleChangeDate(date)
                            }}
                            autoFocus
                            slotProps={{
                                textField:{
                                    required:true,
                                    id:'born',
                                    error: bornError,
                                    helperText:bornErrorMessage
                                },
                            }}
                        />
                    </Stack> 
                </LocalizationProvider>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="indirizzo"
                    label={!indirizzoError ? t('Indirizzo') : t("Errore")}
                    name="indirizzo"
                    autoComplete="indirizzo"
                    autoFocus
                    value={indirizzo}
                    onChange={(event) => handleChange(event, 'indirizzo')}
                    error={indirizzoError}
                    helperText={
                        indirizzoError ? t("Richieste solo lettere, spazi, numeri, virgola e meno e /") : ""
                    }
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="citta"
                    label={!cittaError ? t('Città'): t("Errore")}
                    name="citta"
                    autoComplete="citta"
                    autoFocus
                    value={citta}
                    onChange={(event) => handleChange(event, 'citta')}
                    error={cittaError}
                    helperText={
                        cittaError ? t("Campo Obbligatorio - Richieste lettere e spazi") : ""
                    }  
                />

                <Grid container sx={{}}>
                    <Grid item xs sx={{marginRight:'5px'}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="cap"
                            label={!capError ? t('CAP'): t("Errore")}
                            name="cap"
                            autoComplete="cap"
                            autoFocus
                            type='number'
                            value={cap}
                            onChange={(event) => handleChange(event, 'cap')}
                            error={capError}
                            helperText={
                                capError ? t("Campo Obbligatorio - Richiesti solo Numeri") : ""
                            }
                        />
                    </Grid>
                    <Grid item sx={{marginLeft:'5px'}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="provincia"
                            label={!provinciaError ? t('Provincia'): t("Errore")}
                            name="provincia"
                            autoComplete="provincia"
                            autoFocus
                            value={provincia}
                            onChange={(event) => handleChange(event, 'provincia')}
                            error={provinciaError}
                            helperText={
                                provinciaError ? t("Campo Obbligatorio - Richieste lettere") : ""
                            }
                            inputProps={{ maxLength: 5 }}
                        />
                    </Grid>
                </Grid>

                <SearchNations setNazione={setNazione} nazioneError={nazioneError} setNazioneError={setNazioneError} />

                <SearchAssociations setSelectedAssociation={setSelectedAssociation} associationError={associationError} setAssociationError={setAssociationError}/>
                <Typography variant='body1' textAlign={'justify'}>
                    {t("Se non sei ancora iscritto in nessuna delle associazioni apprtenenti al circuito Asso, puoi consultare l'elenco, scegliere e contattare quella più vicina alla tua zona, cliccando ")}<a href={settings.AssociationsListUrl} target='_blank'>{t("QUI")}</a>
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={!mailError ? t('Indirizzo Email'): t("Errore")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={mail}
                    onChange={(event) => handleChange(event, 'email')}
                    error={mailError}
                    helperText={
                        mailError ? t("Inserisci un indirizzo email valido") : ""
                    }
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={!passwordError ? t('Password'): t("Errore")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => handleChange(e, "password")}
                    error={passwordError}
                    onBlur={(e) => handlePasswordCheckOnBlur(e)} 
                    helperText={
                        passwordError ? t(passwordErrorMessage) : ""
                    }
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label={!passwordConfirmError ? t('Conferma Password') : t("Errore") }
                    type="password"
                    id="confirmPassword"
                    onChange={(e) =>  handleChange(e, 'confirmPassword')}
                    error={passwordConfirmError}
                    onBlur={(event) =>handleConfirmPasswordCheckOnBlur(event)} // faccio un check se la pw va bene quando esco
                    helperText={
                        passwordConfirmError ? t(passwordConfirmErrorMessage) : ""
                    }
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!isFormCorrect || isSubmit}
                >
                    {t('Registrati')}
                </Button>

            </Box>

            { genericMessage && 
                <Box
                    sx={{
                        maxHeight: '200px',
                        overflow:'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    <Typography variant='h6'
                        sx={{color: genericMessageIsError ? "red" : "green"}}
                    >
                        { genericMessageText }
                    </Typography> 
                </Box>
            }

            <Box sx={{
                height: 40,
            }}/>
        </Container>
    )
}