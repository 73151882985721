import { useEffect } from "react";
import { Container, Box, Avatar, Typography } from "@mui/material"
import CancelIcon  from '@mui/icons-material/CancelOutlined';
import { useTranslation } from "react-i18next";

export default function Home () {
    const { t } = useTranslation();

    return (
        <Container 
            component="main" 
            maxWidth="xs" 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // border:'1px solid rgb(128, 128, 128, 0.35)',
                margin:'30px 0px 30px 0px',
                borderRadius:'5px'
            }}
        >
            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Typography component="h1" variant="h3">
                Asso Provider
            </Typography>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Typography variant="h4"
                sx={{
                    paddingBottom:'30px'
                }}
            >
                {t("Benvenuti in Asso Provider")}
            </Typography>

            <Typography variant="h5">
                {t("L'utilizzo del nostro servizio è dedicato solamente alle piattaforme convenzionate e ai relativi utenti delle piattaforme stesse.")}
            </Typography>

            <Box sx={{
                height: 40,
            }}/>
        </Container>
    )
}