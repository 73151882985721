import { Container, styled, Box, Grid } from '@mui/material';
import { WHITE, BLUE_LIGHT_RGB_BORDER } from '../../theme/colors';
import { isMobile } from 'react-device-detect';


interface HeaderContainerProps {
  isScrolled?:boolean
  menuMobileOpen?: boolean
}
  
export const HeaderContainer = styled('div')<HeaderContainerProps>(({theme, isScrolled=false, menuMobileOpen=false})=>({
  top:'0px',
  left:'0px',
  right:'0px',
  position:'fixed',
  display:'flex',
  height: !isMobile ? '70px' : '65px',
  backgroundColor:WHITE,
  borderBottom: "1px solid "+BLUE_LIGHT_RGB_BORDER,
  zIndex:1,
}))


export const Logo = styled('img')<HeaderContainerProps>(({theme, isScrolled=false})=>({
  height: !isMobile ? '60px' : '50px',
  marginTop:'0px',
  [theme.breakpoints.only('xs')]: {
  },
}))

interface GridProps {
  
}

export const GridLogin = styled(Grid)<GridProps>(({theme})=>({
  width:'120px',
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  padding:'0px !important',
  [theme.breakpoints.only('xs')]:{
    width:'100px'
  }
}))
  