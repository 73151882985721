import { Container, styled } from '@mui/material';
import { WHITE } from '../theme/colors';

export const AppContainer = styled('div')(({theme})=>({
    display:'flex',
    textAlign:'center',
    flexDirection: 'column',
    minHeight:'100vh'
  }))
  
export const HeaderContainer = styled('div')(({theme})=>({
    top:'0px',
    left:'0px',
    width:'100%',
    position:'fixed',
    display:'flex',
    height:'100px',
  
    border:'1px solid green'
  }))
  
export const ContainerCustom = styled(Container)(({theme})=>({
    position:'relative',
    display:'flex',
    flexGrow:1,
    justifyContent:'center',
    alignItems:'center'
  }))
  
export const FooterContainer = styled('div')(({theme})=>({
    bottom:'0px',
    left:'0px',
    width:'100%',
    position:'fixed',
    display:'flex',
    height:'50px',
    // backgroundColor:WHITE,
  
    border:'1px solid yellow'
  }))