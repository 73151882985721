import React, {useEffect} from 'react';
import { AppContainer, 
  HeaderContainer, 
  ContainerCustom, 
  FooterContainer } from './template'
  import { useTranslation } from 'react-i18next';
  import { Grid, Typography } from '@mui/material';
  import { isMobile } from 'react-device-detect';
  import settings from '../utilities/settings';
import { BLACK } from '../theme/colors';
import Header from '../components/Header';

interface LayoutProps {
  withFooter:boolean
  children: React.ReactNode
}

const LayoutWithHeader: React.FC<LayoutProps> = ({ withFooter, children }) => {
  
  return (
    <AppContainer>
      <Header />

      <ContainerCustom
        maxWidth='lg'
      >
        { children }
      </ContainerCustom>

      {
        withFooter && <FooterContainer>
          <Grid container>
            <Grid xs={12} item sx={{
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems: 'center'
            }}>
              <Typography sx={{
                fontSize:'0.70rem',
                fontWeight:'bold',
                color: BLACK
              }}>{settings.Details.Owner.Name} &reg;</Typography>
              <Typography sx={{
                fontSize:'0.68rem',
                color: BLACK
              }}>{settings.Details.Owner.Address}</Typography>
              <Typography sx={{
                fontSize:'0.68rem',
                color: BLACK
              }}>{settings.Details.Owner.VAT}</Typography>
            </Grid>
          </Grid>
        </FooterContainer>
      }
      
    </AppContainer>
  );
}

export default LayoutWithHeader;
