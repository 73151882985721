import React, {useEffect} from 'react';
import { AppContainer, 
  HeaderContainer, 
  ContainerCustom, 
  FooterContainer } from './template'
  import { useTranslation } from 'react-i18next';
import SignIn from '../components/auth/SignIn';

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t, i18n } = useTranslation();

  useEffect(()=>{
    i18n.changeLanguage('it')
  },[])

  return (
    <AppContainer>
      {/* <HeaderContainer></HeaderContainer> */}
      
      <ContainerCustom
        maxWidth='lg'
      >
        { children }
      </ContainerCustom>

      {/* <FooterContainer></FooterContainer> */}
    </AppContainer>
  );
}

export default Layout;
