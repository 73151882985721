import { useEffect } from "react";
import { Container, Box, Avatar, Typography } from "@mui/material"
import CancelIcon  from '@mui/icons-material/CancelOutlined';
import { useTranslation } from "react-i18next";

export default function Error () {
    const { t } = useTranslation();

    return (
        <Container 
            component="main" 
            maxWidth="xs" 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                //border:'1px solid rgb(128, 128, 128, 0.35)',
                // margin:'30px 0px 30px 0px',
                borderRadius:'5px'
            }}
        >
            {/* <Box
                sx={{
                    marginTop: 8,
                }}
            /> */}

            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: 'red', color: 'white', alignItems:'center' }}>
                <CancelIcon sx={{fontSize:50}} />
            </Avatar>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            
            <Typography component="h1" variant="h3">
                {t("Errore")}
            </Typography>
            

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Box
                sx={{
                    maxHeight: '200px',
                    overflow:'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                <Typography variant="h4">
                    {t("C'è stato un errore durante l'elaborazione della richiesta.")}
                </Typography>
            </Box>
            
            <Box
                sx={{
                    height: 40,
                }}
            />
        </Container>
    )
}