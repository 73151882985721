const jsonEnv = require(`../config/appsettings.json`);
export default {
	...jsonEnv,
	dropdownLanguages: [
		{ code: 'it', label: 'Italiano' },
		{ code: 'en', label: 'English' },
		{ code: 'de', label: 'Deutsch' },
		{ code: 'sl', label: 'Slovenščina' }
	]
};
