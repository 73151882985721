import * as React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { IRequestUpdatePassword } from '../../../../models/authmodels';
import { useSearchParams } from 'react-router-dom';
import { updatePassword } from '../../../../hooks/auth/actions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { delay } from '../../../../utilities/delay';
import DropDownLanguages from '../../../DropDownLanguages';
import settings from '../../../../utilities/settings';

export default function UpdateFromLink() {
    dayjs.extend(customParseFormat);
    const { t } = useTranslation();
    const [searchParams] = useSearchParams(); // parametri url

    const ReturnUrl = searchParams.get("ReturnUrl")!
    const Code = searchParams.get("Code")!
    const UserId = searchParams.get("UserId")!

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorMessage, setPasswordErroMessage] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const [passwordConfirmErrorMessage, setPasswordConfirmErroMessage] = useState('');

    const [genericMessage, setGenericMessage] = useState(false)
    const [genericMessageIsError, setGenericMessageIsError] = useState(false)
    const [genericMessageText, setGenericMessageText] = useState('')

    const [isSubmit, setIsSubmit] = useState(false);

    const minPasswordLength = 8;

    useEffect(()=>{
         // reset messaggio errore generico
         setGenericMessage(false)
         setGenericMessageIsError(false)
         setGenericMessageText('')
    },[])

    function removeFirstSpaceChar(str:string) {
        if (str.charAt(0) === ' ') {
          return str.slice(1);
        } else {
          return str;
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key:string) => {
        switch(key){
            case 'password':{
                const value = removeFirstSpaceChar(e.target.value)
                setPassword(e.target.value)
                setPasswordError(false);
                setPasswordErroMessage("");
            } break
            case 'confirmPassword':{
                setConfirmPassword(e.target.value)
                setPasswordConfirmError(false)
                setPasswordConfirmErroMessage('')
            }break
        }
    };

    const handlePasswordCheckOnBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\-_+=])(?=.*[0-9])[A-Za-z0-9!@#$%^&*()\\-_+=]+$/
        const isValid = pwRegex.test(e.target.value)
        if (e.target.value.length < minPasswordLength){
            setPasswordError(true)
            setPasswordErroMessage(`La password deve contenere almeno ${minPasswordLength} caratteri`)
            return
        }
        if(!isValid)
        {
                setPasswordError(true);
                setPasswordErroMessage(`Password non valida! Deve contenere almeno 1 maiuscola, 1 minuscola, 1 carattere speciale e 1 numero e non avere spazi`)
        }else{
            setPasswordError(false)
            setPasswordErroMessage("")
        }
    }

    const handleConfirmPasswordCheckOnBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (password !== confirmPassword){ 
            setPasswordConfirmError(true);
            setPasswordConfirmErroMessage(`Le password non corrispondono`)
        }else{
            setPasswordConfirmError(false);
            setPasswordConfirmErroMessage(``);
        }
    }

    const isFormCorrect = ( 
        password === '' ||
        password !== confirmPassword
    ) ? false : true

    function checkAreNotEmpies(): boolean {
        let areCorrects = true;
        // reset messaggio errore generico
        setGenericMessage(false)
        setGenericMessageIsError(false)
        setGenericMessageText('')

        if(password === '') {setPasswordError(true); areCorrects = false}
        if(password !== confirmPassword) {setPasswordConfirmError(true); setPasswordConfirmErroMessage("Le password non corrispondono"); areCorrects = false}
        
        if (!areCorrects) {
            setGenericMessage(true)
            setGenericMessageIsError(true)
            setGenericMessageText(t("Ci sono degli errori nel form"))
        }
        
        return areCorrects;
    }
    
    function inputClean(){
        setPassword('')
        setConfirmPassword('')
    } 

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if( !passwordError &&
            !passwordConfirmError
        ){
            const areCorrects = checkAreNotEmpies();
            if(!areCorrects) return;

            if( Code === '' || UserId === ''){
                setGenericMessage(true)
                setGenericMessageIsError(true)
                setGenericMessageText(t("Non sono presenti parametri indispansabili per l'aggiornamento password."))
            }

            setIsSubmit(true)
            // se tutto va bene:
            const resetPwModel: IRequestUpdatePassword = {
                userId: UserId,
                code: Code,
                password: password
            };

            await updatePassword(resetPwModel).then(resp =>{
                inputClean()
                setGenericMessage(true)
                setGenericMessageIsError(false)
                const message = "Aggiornamento password avvenuto con successo."
                setGenericMessageText(t(message))
                delay(5000).then(()=>{
                    const queryString = ReturnUrl ? "?ReturnUrl=" + encodeURIComponent(ReturnUrl) : "";
                    window.location.href = `/account/login` + queryString
                })
            }).catch(e => {
                setGenericMessage(true)
                setGenericMessageIsError(true)
                const message:any = e?.response?.data || e?.response || e || "Errore durante la richiesta di cambio password. Riprova più tardi."
                setGenericMessageText(t(message))
            })
        }else{
            setGenericMessage(true)
            setGenericMessageIsError(true)
            setGenericMessageText(t("Ci sono degli errori nel form"))
        }
    };
  
    return (
        <Container component="main" maxWidth="xs" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border:'1px solid rgb(128, 128, 128, 0.35)',
            margin:'30px 0px 30px 0px',
            borderRadius:'5px'
        }}
        >
            <Box
                sx={{
                    padding:'8px 0 0 0',
                    width:'100%',
                    height:'35px',
                    marginBottom:'30px',
                    display:'flex'
                }}
            >
                <Box sx={{
                    padding:'0px',
                    width:'100px',
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'center'
                }}>
                    <img src={"/"+settings.Details.Logo} alt="Plutima Provider" style={{
                        height:'35px',
                        marginTop:'8px'
                    }}/>
                </Box>
                <Box sx={{
                    padding:'0px',
                    flex:1,
                    display:'flex',
                    justifyContent:'end',
                    alignItems:'center'
                }}>
                    <DropDownLanguages />
                </Box>
            </Box>

            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: 'rgb(156, 39, 176)', color: 'white', alignItems:'center' }}>
                <ArrowBackIcon sx={{fontSize:50}}/>
            </Avatar>

            <Box
                sx={{
                    height: 10,
                }}
            />

            <Typography component="h1" variant="h5">
                {t('Reset Password')}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                
            <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={!passwordError ? t('Password'): t("Errore")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => handleChange(e, "password")}
                    error={passwordError}
                    onBlur={(e) => handlePasswordCheckOnBlur(e)} 
                    helperText={
                        passwordError ? t(passwordErrorMessage) : ""
                    }
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label={!passwordConfirmError ? t('Conferma Password') : t("Errore") }
                    type="password"
                    id="confirmPassword"
                    onChange={(e) =>  handleChange(e, 'confirmPassword')}
                    error={passwordConfirmError}
                    onBlur={(event) =>handleConfirmPasswordCheckOnBlur(event)} // faccio un check se la pw va bene quando esco
                    helperText={
                        passwordConfirmError ? t(passwordConfirmErrorMessage) : ""
                    }
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!isFormCorrect || isSubmit}
                >
                    {t('Salva nuova password')}
                </Button>

            </Box>

            { genericMessage && 
                <Box
                    sx={{
                        maxHeight: '200px',
                        overflow:'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    <Typography variant='h6'
                        sx={{color: genericMessageIsError ? "red" : "green"}}
                    >
                        { genericMessageText }
                    </Typography> 
                </Box>
            }

            <Box sx={{
                height: 40,
            }}/>
        </Container>
    )
}