import { useEffect, useState } from "react";
import { Container, Box, Avatar, Typography } from "@mui/material"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { logout } from "../../../hooks/auth/actions";
import { delay } from "../../../utilities/delay";
import CustomWaiter from "../../Waiter";

export default function Logout () {
    const { t } = useTranslation();
    let [params] = useSearchParams();
    const [waiterView, setWaiteView] = useState(true);

    useEffect(() => {
        const logoutId = params.get("logoutId")
            if(logoutId){
                logout(logoutId).then(response=>{
                    setWaiteView(false)
                    delay(1500).then(a => {
                        if (response?.postLogoutRedirectUri) {
                            console.log("redirect logout to ", response?.postLogoutRedirectUri)
                            
                            window.location.href = response?.postLogoutRedirectUri || "/"
                        }
                    }).catch(e=> setWaiteView(false))
                }).catch(e=>{console.log("Logout error: ", e)})
            }else{
                setWaiteView(false)
            } 
	}, []);

    return (
        <Container 
            component="main" 
            maxWidth="xs" 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                //border:'1px solid rgb(128, 128, 128, 0.35)',
                // margin:'30px 0px 30px 0px',
                borderRadius:'5px'
            }}
        >
            {/* <Box
                sx={{
                    marginTop: 8,
                }}
            /> */}

            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: 'rgb(156, 39, 176)', color: 'white', alignItems:'center' }}>
                <ExitToAppIcon sx={{fontSize:50}}/>
            </Avatar>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            {
                waiterView && 
                <>
                    <Box sx={{
                        margin:0,
                        padding:0,
                        height:'50px'
                    }}/>

                    <CustomWaiter />
                </>
            }

            <Box
                sx={{
                    maxHeight: '200px',
                    overflow:'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                <Typography>
                    {t('Logout in corso...')}
                </Typography>
            </Box>
            
            <Box
                sx={{
                    height: 40
                }}
            />
        </Container>
    )
}