import { useEffect, useState } from "react";
import { Container, Box, Avatar, Typography } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { delay } from "../../../utilities/delay";
import CustomWaiter from "../../Waiter";

export default function ConfirmMail () {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams(); // parametri url
    const [isVisiblePage, setIsVisiblePage] = useState(false);
    const userName = searchParams.get("userName")
    
    useEffect(()=>{
        if(userName === null)
            delay(2000).then(reps => {
                window.location.href = "/"
            });
        else{
            delay(2000).then(reps => {
                setIsVisiblePage(true)
            });
        }
    },[])

    return (
        <Container 
            component="main" 
            maxWidth="xs" 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius:'5px'
            }}
        >
            {isVisiblePage ? ( <>
                <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: 'green', color: 'white', alignItems:'center'}}>
                    <CheckCircleIcon  sx={{ fontSize: 50 }}/>
                </Avatar>

                <Box sx={{
                    margin:0,
                    padding:0,
                    height:'50px'
                }}/>

                <Typography component="h1" variant="h3">
                    {userName}
                </Typography>

                <Typography component="h1" variant="h3">
                    {t("Registrazione avvenuta con successo")}
                </Typography>

                <Box sx={{
                    margin:0,
                    padding:0,
                    height:'30px'
                }}/>

                <Box
                    sx={{
                        maxHeight: '200px',
                        overflow:'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    <Typography variant="h4">
                        {t("Grazie per esserti registrato. Controlla la mail e premi il link di conferma che ti abbiamo inviato. Passati 30 giorni senza conferma l'account verrà rimosso.")}
                    </Typography>
                </Box>
                
                <Box sx={{
                    height: 40,
                }}/>
            </>
        ) : (
            <CustomWaiter />
        )}
        </Container>
    )
}

