import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IType } from '../../../models/authmodels';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { INPUT_FONT_COLOR_BLACK } from '../../../theme/colors';

interface SetTypeProps {
  type: number,
  setType: React.Dispatch<React.SetStateAction<number>>
}

const SelectType: React.FC<SetTypeProps> = ({ type, setType }) => {
    const { t } = useTranslation();
    const types:IType[] = [
      {value:1, label: t('ASSOCIAZIONE')},
      {value:2, label: t('AZIENDA')}
    ]

    const onChange = (event: SelectChangeEvent<number>) => {
      const selectedValue = event.target.value as number;
      setType(selectedValue);
    };

    return (
      <FormControl fullWidth variant="outlined" sx={{
        margin:'16px 0 8px 0'
      }}>
        <Select size="medium"
          value={type}
          onChange={(event: SelectChangeEvent<number>) => {
            onChange(event)
          }}
          sx={{
            height:'56px',
            color: INPUT_FONT_COLOR_BLACK,
            fontSize:'1em',
            textAlign:'initial',
            '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
              paddingTop: '6px',
              paddingLeft:'10px',
            },
            
            '& .MuiSelect-icon':{
              color: INPUT_FONT_COLOR_BLACK
            },
          }}
        >
        {types.map((value, index) => {
          return (
            <MenuItem key={index} value={value.value} sx={{
                fontSize:'0.8em'
            }}>
                {value.label.toUpperCase()} *
            </MenuItem>
          )
        })}
        </Select>
      </FormControl>
    );
  }

  export default SelectType;