
import { HeaderContainer, Logo, GridLogin} from "./template";
import DropDownLanguages from "../DropDownLanguages";
import {  WHITE } from "../../theme/colors";
import { Grid} from "@mui/material";
import settings from "../../utilities/settings";

const Header = () =>{
    return (
        <>
            <HeaderContainer id="header">
                <Grid container spacing={0} flexGrow={1} marginTop={0} sx={{backgroundColor:WHITE}}>
                    <Grid item xs={4} sx={{
                        flexGrow:1,
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        margin:'0px !important',
                        padding:'0px !important',
                        backgroundColor:WHITE
                    }}>
                        <Logo src={"/"+settings.Details.Logo} alt="Plutima Provider"/>
                    </Grid>
                    <Grid item xs={8} sx={{
                        flexGrow:1,
                        display:'flex',
                        justifyContent:'end',
                        alignItems:'center',
                        backgroundColor:WHITE
                    }}>
                        <GridLogin item margin={'0px 30px 0 5px'}>
                            <DropDownLanguages />
                        </GridLogin>
                    </Grid>
                </Grid>
            </HeaderContainer>
        </>
    )
}

export default Header