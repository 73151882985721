import { Container, styled } from '@mui/material';
import { BLUE_LIGHT_RGB_BORDER, GRAY_LIGHT_RGB_BORDER, WHITE } from '../theme/colors';

export const AppContainer = styled('div')(({theme})=>({
    display:'flex',
    textAlign:'center',
    flexDirection: 'column',
    minHeight:'100vh'
  }))
  
export const HeaderContainer = styled('div')(({theme})=>({
    top:'0px',
    left:'0px',
    width:'100%',
    position:'fixed',
    display:'flex',
    height:'100px',
    backgroundColor:WHITE,
    borderBottom: "1px solid "+BLUE_LIGHT_RGB_BORDER,
  }))
  
export const ContainerCustom = styled(Container)(({theme})=>({
    position:'relative',
    display:'flex',
    flexGrow:1,
    // paddingBottom:'60px',
    // paddingTop:'110px',
    justifyContent:'center',
    alignItems:'center'
  }))
  
export const FooterContainer = styled('div')(({theme})=>({
    bottom:'0px',
    left:'0px',
    width:'100%',
    position:'fixed',
    display:'flex',
    height:'50px',
    backgroundColor:WHITE,
    borderTop:"1px solid " + BLUE_LIGHT_RGB_BORDER
  }))