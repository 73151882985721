import axios from 'axios'
import settings from './settings'
import Cookies from "js-cookie"

export const getCookies = () => {
		// console.log("COOKIE FANNO PAM PAM PAM",Cookies.get())
		return Cookies.get();
};

const httpClient = axios.create({
	baseURL: settings.Domains.IdentityApi + "/api",
	headers: {
		"content-type": "application/json",
	},
	responseType: "json",
	withCredentials: true 
});

httpClient.interceptors.request.use(
	config => {
		if (typeof window !== "undefined") {
			// getCookies()
			const { Authorization } = getCookies(); 
			if (Authorization) {
				config.headers = config.headers ?? {}
				config.headers.Authorization = Authorization;
			}
		}
		return config;
	},
	error => Promise.reject(error)
);

export default httpClient;