import { createTheme } from "@mui/material/";
import { breakpoints } from "./breakpoints";
import { palette, BLACK, BLUE_MAIN, GRAY_MAIN, WHITE, GRAY_LIGHT, BLUE_LIGHT, GREEN_LIGHT, BLUE_DARK } from "./colors";
import { spacing } from "./spacing";
import { fontWeightClasses, typography, worksans } from "./typography";

export const theme = createTheme({
	typography,
	palette,
	breakpoints,
	spacing,
	shape: {
		borderRadius: 2,
	},
	components:{
		MuiInputBase: {
			styleOverrides:{
				root:{
					
				}
			}
		},
		MuiOutlinedInput:{
			styleOverrides:{
				root:{
					borderRadius:'5px'
				}
			}
		},
		MuiButton:{
			styleOverrides:{
				root: {
					borderRadius: 5,
					textTransform: "none",
					// fontSize: typography.button?.fontSize,
					// fontWeight: typography.button?.fontWeight,
					padding: "10px 15px",
					"&$disabled": {
						backgroundColor: GRAY_MAIN,
						color: WHITE,
					},
				},
				sizeSmall: {
					padding: "11px 22px",
					// fontWeight: 600,
				},
				contained: {
					// backgroundColor: BLUE_MAIN,
					color: WHITE,
					"&$disabled": {
						backgroundColor: GRAY_MAIN,
						color: WHITE,
					},
				},
				containedPrimary: {
					color: WHITE,
					"&$disabled": {
						backgroundColor: GRAY_MAIN,
						color: WHITE,
					},
				},
				containedSecondary: {
					color: WHITE,
					"&$disabled": {
						backgroundColor: GRAY_MAIN,
						color: WHITE,
					},
				},
			}
		},
		// MuiButton:{
		// 	styleOverrides:{
				
		// 		root:{
		// 			height:'30px',
		// 			width:'100%',
		// 			// maxWidth:'100px',
		// 			borderRadius:'15px',
		// 			color: BLUE_MAIN,
		// 			border:'2px solid '+ BLUE_MAIN,
		// 			fontSize:'0.7em',
		// 			//padding:'0px',
		// 		}
		// 	}
		// },
		MuiSelect:{
			styleOverrides:{
				root:{
					height:'30px',
					color: BLUE_MAIN,
					fontSize:'0.7em',
					'& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
						paddingTop: '6px',
						paddingLeft:'10px',
					},
					
					'& .MuiSelect-icon':{
						color: BLUE_MAIN
					},
					
					'& .MuiInputBase-root::before': {
						borderBottomColor: BLUE_MAIN
					},
					'& .MuiInputBase-root::after': {
						borderBottomColor: BLUE_DARK
					},
				},
			}
		}
		// MuiDialogTitle: {
		// 	styleOverrides:{
		// 		root:{
		// 			backgroundColor: 'blue',
		// 			padding: spacing(4),
		// 			[breakpoints.down("md")]: {
		// 				padding: spacing(3),
		// 			},
		// 			paddingBottom: 0,
		// 			fontSize: 27,
		// 			fontWeight: "normal",
		// 			textAlign: "center",
		// 		},
		// 	}
		// },
	},
});
