import React, { ReactNode } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate, Link} from "react-router-dom";
import Layout from "./Layout";
import SignIn from './components/auth/SignIn';
import Register from './components/auth/Register';
import Logout from './components/auth/Logout';
import Error from './components/Error';
import ConfirmMail from './components/auth/ConfirmMail';
import Confirm from './components/auth/Confirm';
import AcceptRejectUser from './components/auth/AcceptRejectUser';
import LayoutWithHeader from './LayoutWithHeader';
import Home from './components/Home';
import RequestResetLink from './components/auth/Password/RequestResetLink';
import UpdateFromLink from './components/auth/Password/UpdateFromLink';
import NotFound from './components/NotFound';
import RegisterCompanyAssociation from './components/auth/RegisterCompanyAssociation';

function App() {
  return (
    <Routes>        
      <Route path="/" element={<LayoutWithHeader withFooter={true} children={<Home />}/>} />
      <Route path="/account" element={<Navigate to="/"/>} />
      <Route path="/account/login" element={<Layout children={<SignIn/>}/>} />
      <Route path="/account/register" element={<Layout children={<Register/>}/>} />
      <Route path="/account/register-company" element={<Layout children={<RegisterCompanyAssociation/>}/>} />
      <Route path="/account/logout" element={<LayoutWithHeader withFooter={false} children={<Logout/>}/>} />
      <Route path="/error" element={<LayoutWithHeader withFooter={false} children={<Error/>}/>} />
      <Route path="/account/confirm-mail" element={<LayoutWithHeader withFooter={false} children={<ConfirmMail />} />} />
      <Route path="/account/confirm" element={<LayoutWithHeader withFooter={false} children={<Confirm />} />} />
      <Route path="/association/userconfirm/:action" element={<LayoutWithHeader withFooter={false} children={<AcceptRejectUser />} />} />
      <Route path="/account/password/request-reset" element={<Layout children={<RequestResetLink />} />} />
      <Route path="/account/password/update" element={<Layout children={<UpdateFromLink />} />} />

      <Route path="*" element={<Navigate to="/404" />} />
      <Route path="/404" element={<LayoutWithHeader withFooter={false} children={<NotFound />} />}  /> 
    </Routes>
  );
}

export default App;

interface RedirectProps{
  url: string
}
const Redirect:React.FC<RedirectProps> = ({url}) => {
  window.location.href = url;
  return null;
};