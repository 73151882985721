import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_it from './locales/it/common.json';
import common_de from './locales/de/common.json';
import common_en from './locales/en/common.json';
import common_slo from './locales/slo/common.json';

const userLanguage = window.navigator.language.split('-')[0] || 'it';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      it: {
        translation: common_it,
      },
      de: {
        translation: common_de,
      },
      en: {
        translation: common_en,
      },
      sl: {
        translation: common_slo,
      }
    },
    lng: window.navigator.language || 'it', // Lingua predefinita
    fallbackLng: 'it', // Lingua di fallback nel caso in cui la lingua richiesta non sia disponibile
    interpolation: {
      escapeValue: false, // Requisito per la formattazione di React
    },
  });

export default i18n;