import React from 'react';
import { CircularProgress } from '@mui/material';

// position: 'absolute', top: 0, left: 0

const CustomWaiter = () => {
  return (
    <div style={{ width: '100px', height: '100px' }}>
      <CircularProgress style={{  }} color="primary" size={80} thickness={5} />
    </div>
  );
};

export default CustomWaiter;