import { PaletteOptions } from "@mui/material";

export const WHITE = "#fff";
export const BLACK = "rgba(0, 0, 0, 0.87)";
export const INPUT_FONT_COLOR_BLACK= "rgba(0, 0, 0, 0.6);ù";

export const GRAY_LIGHT = "#e0e0e0";
export const GRAY_MAIN = "#8d8d8d";
export const GRAY_DARK = "#8d8d8d";
export const GRAY_LIGHT_RGB_BORDER = "rgb(192, 192, 192, 0.4)"

export const GREEN_LIGHT = "#def7f2";
export const GREEN_MAIN = "#01e6ba";
export const GREEN_DARK = "#01e6ba";

export const BLUE_LIGHT = "#0077ff";
export const BLUE_MAIN = "#0a52c6";
export const BLUE_DARK = "#172178";
export const BLUE_LIGHT_RGB_BORDER = "rgb(135, 206, 250, 0.4)"

export const palette: PaletteOptions = {
	mode: "light",
	text:{
		primary: BLUE_DARK
	},
	primary: {
		main: BLUE_LIGHT,
		light: BLUE_LIGHT,
		dark: BLUE_DARK,
	},
	secondary: {
		main: GREEN_MAIN,
		light: GREEN_LIGHT,
		dark: GREEN_DARK,
	},
	background: {
		default: BLUE_MAIN,
		paper: WHITE,
	},
	success: {
		main: "#00f2c3",
	},
};
