import { useEffect, useState, useRef } from "react";
import { Container, Box, Avatar, Typography } from "@mui/material"
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { delay } from "../../../utilities/delay";
import CustomWaiter from "../../Waiter";
import { confirmEmail } from "../../../hooks/auth/actions";

export default function Confirm () {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams(); // parametri url
    const initialized = useRef(false)
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState("black");
    const code = searchParams.get("code") as string;
    const userId = searchParams.get("userId") as string;
    const returnUrl = searchParams.get("returnUrl") ? searchParams.get("returnUrl") as string : "/";
    const userRef = searchParams.get("userRef");
    const [waiterView, setIsWaiter] = useState(true);

    useEffect(()=>{
        console.log("URL", returnUrl, userRef, `${returnUrl}${userRef ? `&userRef=${userRef}` : null}`)
        if (!initialized.current) {
            initialized.current = true
            if(userId === null || code === null)
                delay(5000).then(reps => {
                    setMessage("Richiesta di validazione errata. Riprova.")
                    setMessageColor("red")
                    setIsWaiter(false)
                    window.location.href = "/"
                });
            else{
                setMessageColor('black')
                setMessage("Validazione della mail in corso...")
                
                handleConfirmEmail()
            }
        }   
    },[code])

    const handleConfirmEmail = async () =>{
        try{
            const {data} = await confirmEmail({ userId, code, returnUrl });
            // console.log("mail validata", data)
            setMessage("Mail validata con successo")
            setMessageColor('green')
            await delay(2000)
            setMessage("Resta in attesa di esser trasferito per completare l'operazione...")
            setMessageColor('green')
            await delay(3000)
            setIsWaiter(false)
            window.location.href = `${returnUrl}?userId=${userId}&code=${code}${userRef ? `&userRef=${userRef}` : null}`
        }catch(e:any){
            const err = e?.response?.data || e?.response?.status || e?.message || e;
            console.log("error", e)
            setMessage(err)
            setMessageColor('red')
            await delay(5000)
            setIsWaiter(false)
            window.location.href = "/"
        }
    }

    return (
        <Container 
            component="main" 
            maxWidth="xs" 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                //border:'1px solid rgb(128, 128, 128, 0.35)' ,
                // margin:'30px 0px 30px 0px',
                borderRadius:'5px'
            }}
        >
            
                {/* <Box
                    sx={{
                        marginTop: 8,
                    }}
                /> */}

                <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: 'blue', color: 'white', alignItems:'center' }}>
                    <EmailIcon sx={{ fontSize: 50 }}/>
                </Avatar>

                <Box sx={{
                    margin:0,
                    padding:0,
                    height:'50px'
                }}/>

                <Typography component="h1" variant="h3">
                    {t("Conferma email")}
                </Typography>

                { waiterView && 
                    <>
                        <Box sx={{
                            margin:0,
                            padding:0,
                            height:'50px'
                        }}/>

                        <CustomWaiter />
                    </>
                }

                <Box sx={{
                    margin:0,
                    padding:0,
                    height:'50px'
                }}/>

                <Box
                    sx={{
                        maxHeight: '200px',
                        overflow:'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    <Typography color={messageColor} variant="h4">
                        {t(message)}
                    </Typography>
                </Box>
                
                <Box sx={{
                    height: 40,
                }}/>
        </Container>
    )
}

