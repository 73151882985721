import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { login } from '../../../hooks/auth/actions';
import { delay } from '../../../utilities/delay';
import DropDownLanguages from '../../DropDownLanguages';
import settings from '../../../utilities/settings';

export default function SignIn() {
    const [email, setEmail] = useState<string>('');
    const [mailError, setMailError] = useState<boolean>(false)
    const { t } = useTranslation();
    const [searchParams] = useSearchParams(); // parametri url
    const ReturnUrl = searchParams.get("ReturnUrl") as string
    const queryString = ReturnUrl ? "?ReturnUrl=" + encodeURIComponent(ReturnUrl) : "";
    const [checked, setChecked] = useState(false);
    const [genericMessage, setGenericMessage] = useState(false)
    const [genericMessageIsError, setGenericMessageIsError] = useState(false)
    const [genericMessageText, setGenericMessageText] = useState('')

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(()=>{
        if(!ReturnUrl || ReturnUrl === null || ReturnUrl === '' || ReturnUrl === undefined)
            window.location.href = "/"
    },[])

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const validateEmail = (inputEmail:string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(inputEmail)) {
            setMailError(true);
        } else {
            setMailError(false)
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(event.target.value);
    };

    const handleBlur = () => {
        validateEmail(email);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmit(true)
        const data = new FormData(event.currentTarget);
        const email = data.get('email') as string
        const password = data.get('password') as string

        login(email, password, ReturnUrl, checked)
            .then(({ returnUrl, isOk }) => {
                return delay(300).then(() => {
                    if (isOk) {
                            setGenericMessage(true)
                            setGenericMessageIsError(false)
                            setGenericMessageText(t("Login Effettuato correttamente. Stai per essere reindirizzato alla pagina richiesta..."))
                            delay(2000).then( r => {
                                setIsSubmit(false)
                                window.location.href = returnUrl;
                            }
                        )
                        
                    }
                });
            })
            .catch((e) => {
                console.error('Error:', e);
                setGenericMessage(true)
                setGenericMessageIsError(true)
                const message:any = e?.response?.data || e?.response || e || "Errore durante il login"
                setGenericMessageText(t(message))
                setIsSubmit(false)
            }
        );
    };
  
    return (
        <Container 
            component="main" 
            maxWidth="xs" 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border:'1px solid rgb(128, 128, 128, 0.35)',
                margin:'30px 0px 30px 0px',
                borderRadius:'5px'
            }}
        >
            <Box
                sx={{
                    padding:'8px 0 0 0',
                    width:'100%',
                    height:'35px',
                    marginBottom:'30px',
                    display:'flex'
                }}
            >
                <Box sx={{
                    padding:'0px',
                    width:'100px',
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'center'
                }}>
                    <img src={"/"+settings.Details.Logo} alt="Plutima Provider" style={{
                        height:'35px',
                        marginTop:'8px'
                    }}/>
                </Box>
                <Box sx={{
                    padding:'0px',
                    flex:1,
                    display:'flex',
                    justifyContent:'end',
                    alignItems:'center'
                }}>
                    <DropDownLanguages />
                </Box>
            </Box>

            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: 'rgb(156, 39, 176)', color: 'white', alignItems:'center' }}>
                <LockOutlinedIcon sx={{fontSize:50}}/>
            </Avatar>

            <Box
                sx={{
                    height: 10,
                }}
            />

            <Typography component="h1" variant="h5">
                {t('Accedi')}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={!mailError ? t('Indirizzo Email'): t("Errore")}
                    name="email"
                    autoComplete="email"
                    onChange={(event) => handleChange(event)}
                    onBlur={handleBlur}
                    autoFocus
                    helperText={
                        mailError ? t("Inserisci un indirizzo email valido") : ""
                    }
                    error={mailError}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('Password')}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleChangeCheck} value="remember" color="primary" />}
                    label={t('Ricordami')}
                    sx={{width:'100%', marginTop:'10px'}}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isSubmit}
                >
                    {t('Accedi')}
                </Button>
            </Box>

            { genericMessage && 
                <Box
                    sx={{
                        maxHeight: '200px',
                        overflow:'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    <Typography variant='h6'
                        sx={{color: genericMessageIsError ? "red" : "green"}}
                    >
                        {genericMessageText}
                    </Typography>
                </Box>
            }

            <Grid container sx={{marginTop:'15px'}}>
                <Grid item xs>
                    <Box
                            sx={{
                            width:'100%',
                            textAlign:'left'
                        }}
                    >
                        <Link href={`/account/password/request-reset${queryString}`} variant="body2" sx={{fontSize:'0.8em'}}>
                            {t('Password dimenticata?')}
                        </Link>
                    </Box>
                </Grid>
                <Grid item sx={{justifyContent:'right !important'}}>
                    <Box
                            sx={{
                            width:'100%',
                            textAlign:'right'
                        }}
                    >
                        <Link href={`/account/register`} variant="body2" sx={{fontSize:'0.8em'}}>
                            {t('Non hai un account? Registrati')}
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            
            <Box sx={{
                height: 40,
            }}/>
        </Container>
    )
}