import { useEffect, useState, useRef } from "react";
import { Container, Box, Avatar, Typography } from "@mui/material"
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { delay } from "../../../utilities/delay";
import CustomWaiter from "../../Waiter";
import { acceptRejectUser } from "../../../hooks/auth/actions";
import { DoneAll } from "@mui/icons-material";

export default function AcceptRejectUser () {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams(); // parametri url
    const initialized = useRef(false)
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState("black");

    const token = searchParams.get("token") as string;
    const userId = searchParams.get("userId") as string;
    const { action } = useParams();

    const [waiterView, setWaiteView] = useState(true);
    const [icoColor, setIcoColor] = useState<string | undefined>(undefined)
    
    useEffect(()=>{
        if(action && action === "accept") setIcoColor("green")
        if(action && action === "reject") setIcoColor("red")
    },[])

    useEffect(()=>{
        if (!initialized.current) { // evitare doppie chiamate
            initialized.current = true
            if(userId === null || token === null)
                {
                    setMessage("Richiesta di validazione errata. Riprova.")
                    setMessageColor("red")
                }
            else{
                if(action === undefined){
                    setMessage("Non hai definito se vuoi accettare o rimuovere l'utente. Riprova!")
                    setMessageColor('red')
                }
                else if (action === "accept" || action === "reject"){
                    handleAcceptReject()
                }else {
                    setMessage("Non hai definito se vuoi accettare o rimuovere l'utente. Riprova!")
                    setMessageColor('red')
                }
            }
        }   
    },[action])

    const handleAcceptReject = async () =>{
        try{
            if (action === undefined) {
                setMessage("Non hai definito se vuoi accettare o rimuovere l'utente. Riprova!")
                setMessageColor('red')
                setWaiteView(false)
                return;
            }
            const {data} = await acceptRejectUser({ userId, token, action });
            switch(action){
                case "accept": 
                    {   
                        setMessage("L'utente è stato accettato con successo ad operare nel sistema come tuo associato!")
                        setMessageColor('green')
                        setWaiteView(false)
                    }break
                case "reject": 
                    {
                        setMessage("L'utente è stato rimosso con successo!")
                        setMessageColor('orange')
                        setWaiteView(false)
                    }break
            }
            
        }catch(e:any){
            const err = e?.response?.data || e?.response?.status || e?.message || e;
            console.log("error", e)
            setMessage(err)
            setMessageColor('red')
            setWaiteView(false)
        }
    }

    return (
        <Container 
            component="main" 
            maxWidth="xs" 
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                //border:'1px solid rgb(128, 128, 128, 0.35)' ,
                // margin:'30px 0px 30px 0px',
                borderRadius:'5px'
            }}
        >
            
            {/* <Box
                sx={{
                    marginTop: 8,
                }}
            /> */}

            <Avatar sx={{ m: 1, width: 70, height: 70, backgroundColor: icoColor , color: 'white', alignItems:'center' }}>
                <DoneAll sx={{ fontSize: 50 }}/>
            </Avatar>

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Typography component="h1" variant="h3">
                {t("Accetta o Rifiuta utente")}
            </Typography>

            {
                waiterView && 
                <>
                    <Box sx={{
                        margin:0,
                        padding:0,
                        height:'50px'
                    }}/>

                    <CustomWaiter />
                </>
            }

            <Box sx={{
                margin:0,
                padding:0,
                height:'50px'
            }}/>

            <Box
                sx={{
                    maxHeight: '200px',
                    overflow:'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                <Typography color={messageColor} variant="h4">
                    {t(message)}
                </Typography>
            </Box>
            
            <Box sx={{
                height: 40,
            }}/>
        </Container>
    )
}

